import { ROUTES } from '@ab-core/functions';

export const searchInputFocusExceptions = [
    ROUTES.CART,
    ROUTES.CHECKOUT,
    ROUTES.PRIVACY,
    ROUTES.NEWS,
    ROUTES.HELP,
    ROUTES.CATEGORY,
    ROUTES.SIGNUP,
    ROUTES.SINGUPSUCCESS,
    ROUTES.NEWSLETTER,
    ROUTES.SERVICES
];
