export enum SPACING {
    SMALL = '0.25rem',
    SMALL_2 = '0.5rem',
    SMALL_3 = '0.75rem',
    SMALL_4 = '1rem',
    SMALL_5 = '1.25rem',
    MEDIUM = '1.5rem',
    MEDIUM_2 = '2rem',
    MEDIUM_3 = '2.5rem',
    MEDIUM_4 = '3rem',
    LARGE = '4rem',
    LARGE_2 = '5rem',
    LARGE_3 = '6rem',
    LARGE_4 = '9.5rem',
    NONE = '0rem',
    AUTO = 'auto'
}
