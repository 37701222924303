import type { Mutation, MutationCreateShoppingListArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_SHOPPING_LIST } from './gql/createShoppingList';
import { SHOPPING_LISTS } from './gql/shoppingLists';

type MutationDataType = Pick<Mutation, 'createShoppingList'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createShoppingList');

const refetchQueries = [SHOPPING_LISTS];

export const useCreateShoppingList = (name: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createShoppingList, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateShoppingListArgs
    >(CREATE_SHOPPING_LIST, {
        variables: {
            shoppingListInput: {
                name: name.trim()
            }
        },
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createShoppingList,
        loading,
        response
    };
};
