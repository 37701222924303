import type { Mutation, MutationCreateCostCenterArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_COST_CENTER } from './gql/createCostCenter';
import { GET_COST_CENTERS } from './gql/getCostCenters';

type MutationDataType = Pick<Mutation, 'createCostCenter'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createCostCenter');

const refetchQueries = [GET_COST_CENTERS];

export const useCreateCostCenter = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createCostCenter, { loading, error, data }] = useMutation<MutationDataType, MutationCreateCostCenterArgs>(
        CREATE_COST_CENTER,
        {
            onError,
            refetchQueries
        }
    );

    const outgoingCreateCostCenter = useCallback(
        (args: MutationCreateCostCenterArgs['data']) => {
            createCostCenter({ variables: { data: args } });
        },
        [createCostCenter]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createCostCenter: outgoingCreateCostCenter,
        loading,
        response
    };
};
