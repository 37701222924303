import type { Mutation, MutationCreateOrderFromOfferArgs } from '@ab-core/graphql/dist';
import { priceStore } from '@ab-core/store';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_ORDER_FROM_OFFER } from './gql/createOrderFromOffer';

type MutationDataType = Pick<Mutation, 'createOrderFromOffer'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createOrderFromOffer');

export const useCreateOrderFromOffer = (offerId: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const myCredits = priceStore.getters.useCredits();
    const maxCredits = priceStore.getters.useMaxCredits();
    const coupon = priceStore.getters.useCoupon();
    const credits = myCredits > maxCredits ? maxCredits : myCredits;

    const [createOrderFromOffer, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateOrderFromOfferArgs
    >(CREATE_ORDER_FROM_OFFER, {
        variables: {
            offerId,
            credits,
            coupon
        },
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createOrderFromOffer,
        loading,
        response
    };
};
