import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const CounterWrapper = styled.div`
    text-align: right;
    display: flex;
    white-space: nowrap;
    margin-left: auto;
`;

export const InlineWrapper = styled.div`
    margin-top: ${SPACING.SMALL};
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: ${SPACING.SMALL_3};
    min-height: ${SPACING.SMALL_4};
`;
