import type { Mutation, MutationRenewOfferArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { RENEW_OFFER } from './gql/renewOffer';

type MutationDataType = Pick<Mutation, 'renewOffer'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createOrUpdateFavoriteLinks');

export const useRenewOffer = (offerNumber: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [renewOffer, { loading, error, data }] = useMutation<MutationDataType, MutationRenewOfferArgs>(RENEW_OFFER, {
        variables: { offerNumber },
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        renewOffer,
        loading,
        response
    };
};
