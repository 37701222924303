import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const FavoriteEdit = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.primary};
    border-radius: ${RADIUS.CIRCLE};
    color: ${Colors.white};
`;

export const FavoriteEditClickArea = styled.div`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    height: ${SPACING.MEDIUM_4};
    width: ${SPACING.MEDIUM_4};
    padding: ${SPACING.SMALL_2};
`;
