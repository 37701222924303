import { gql } from '@apollo/client';
import { ACCOUNT_DATA } from './accountFragment';

export const USER_DATA = gql`
    fragment userFields on Customer {
        contact {
            id
            salutation
            userName
            firstName
            lastName
            email
            onlineShopAccess
            fax
            title
            createdAt
            academicTitle
            function
            mobilePhone
            featureAccess {
                feature {
                    id
                    name
                    value
                    amountValue
                    valueText
                }
            }
        }
        accounts {
            ...accountFields
        }
        activeAccount {
            ...accountFields
        }
    }
    ${ACCOUNT_DATA}
`;
