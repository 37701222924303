import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const TabListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${SPACING.SMALL_2} ${SPACING.SMALL_4};
    padding: 0;
    margin: 0;
`;
