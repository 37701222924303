export enum SHARE_STATE {
    PUBLIC = 'public',
    SHARED = 'shared',
    PRIVATE = 'private'
}

export const getShareStateByString = (state?: string) => {
    let shareState = SHARE_STATE.PRIVATE;

    if (Object.values(SHARE_STATE).includes(state as SHARE_STATE)) {
        shareState = state as SHARE_STATE;
    }

    return shareState;
};
