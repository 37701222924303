import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { Icon } from '@ab-core/icon';
import type { WithTestId } from '@ab-core/testing';
import type { GatsbyLinkProps } from 'gatsby';
import { withPrefix } from 'gatsby';
import React from 'react';
import { StyledGatsbyLink, StyledLink } from './link.styled';
import { getLinkState } from './utils';

export type LinkProps = WithTestId & Omit<GatsbyLinkProps<{}>, 'ref'> & { variant?: LINK_VARIANT };

export enum LINK_STATE {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    INTERNAL_BLANK = 'internal-blank'
}

export enum LINK_VARIANT {
    DEFAULT = 'default',
    LINK_ONLY = 'link-only'
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { to, target, children, variant = LINK_VARIANT.DEFAULT, className, ...rest } = props;

    const linkState = getLinkState(to, target);
    const linkClass = getClassNames({ className, prefix: 'link', modifier: { variant } });

    return (
        <>
            {linkState === LINK_STATE.INTERNAL && (
                <StyledGatsbyLink {...rest} to={to} target={target} innerRef={ref} className={linkClass}>
                    {children}
                </StyledGatsbyLink>
            )}
            {linkState === LINK_STATE.INTERNAL_BLANK && (
                <StyledLink {...rest} href={`${withPrefix(to)}`} target={target} ref={ref} className={linkClass}>
                    {children}
                    <Icon className="link-icon" name="ExternalLink" size="SMALL_4" />
                </StyledLink>
            )}
            {linkState === LINK_STATE.EXTERNAL && (
                <StyledLink {...rest} href={to} target={target} ref={ref} className={linkClass}>
                    {children}
                    <Icon className="link-icon" name="ExternalLink" size="SMALL_4" />
                </StyledLink>
            )}
        </>
    );
});
