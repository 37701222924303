import Button, { BUTTON_COLOR, BUTTON_VARIANT } from '@ab-core/button';
import { ROUTES } from '@ab-core/functions';
import { handleInputKeyDownEnter } from '@ab-core/functions/handleInputKeyDownEnter';
import { Col, Row } from '@ab-core/grid';
import { useCreateAccountContactRelation, useGetCustomerData } from '@ab-core/hooks-v2';
import { useSetActiveCart } from '@ab-core/hooks/cartNew/activeCart/checkForActiveCart';
import { Input } from '@ab-core/input';
import { Loader } from '@ab-core/loader';
import useText from '@ab-core/localization/src/useText';
import Modal from '@ab-core/modal';
import Spacer from '@ab-core/spacer';
import { globalStore } from '@ab-core/store';
import { getTestIdProp } from '@ab-core/testing';
import Text from '@ab-core/text';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';

type GhostAccessModalType = {
    open: boolean;
};

export const GhostAccessModal: FC<GhostAccessModalType> = ({ open = false }) => {
    const [loading, setLoading] = useState(false);
    const textHeadline = useText('ghostAccess.headline');
    const textCustomerId = useText('ghostAccess.inputCustomerId');
    const textActivateButton = useText('ghostAccess.activateButton');
    const textDescription = useText('ghostAccess.descriptionText');
    const textErrorMessage = useText('ghostAccess.customerNumberNotFound');

    const { refetch, client } = useGetCustomerData({ skip: true });
    const { checkForActiveCart } = useSetActiveCart();
    const ref = useRef<HTMLInputElement>(null);
    const { createAccountContactRelation, success, response } = useCreateAccountContactRelation();
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = async () => {
        globalStore.setters.setGhostAccess(false);
        setLoading(false);
    };

    const handleGhostActivate = () => {
        const accountNumber = ref?.current?.value?.replace(/\D/g, '');

        if (!accountNumber) {
            return null;
        }

        setLoading(true);
        createAccountContactRelation(ref?.current?.value.toString().replaceAll(' ', ''));
    };

    useEffect(() => {
        if (response && !response.createAccountContactRelation) {
            setErrorMessage(textErrorMessage);
            setLoading(false);
        } else {
            if (success) {
                const dataRefetch = async () => {
                    await refetch({ force: true });
                    await client.resetStore();
                    await checkForActiveCart();
                    await handleClose();
                    await navigate(ROUTES.SHOP);
                };
                dataRefetch();
            }
        }
    }, [success]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            headerText={textHeadline}
            footer={
                <Row justifyContent="between">
                    <Col sm={6} className="pb-small5">
                        <Button variant={BUTTON_VARIANT.OUTLINE} fluid onClick={handleClose} color={BUTTON_COLOR.GRAY}>
                            {useText('customLineItem.cancel')}
                        </Button>
                    </Col>
                    <Col sm={6}>
                        <Button
                            className="ghostActivateButton"
                            fluid
                            disabled={loading}
                            onClick={handleGhostActivate}
                            {...getTestIdProp('activate-ghost-access-button')}
                        >
                            {textActivateButton}
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div>
                {loading && <Loader />}
                <Spacer size="sm" />
                <Text>{textDescription}</Text>
                <Spacer size="xs" />
                <Input
                    onKeyDown={(event) => handleInputKeyDownEnter({ event, callback: handleGhostActivate })}
                    ref={ref}
                    autoFocus
                    errorMessage={errorMessage}
                    label={textCustomerId}
                    {...getTestIdProp('ghost-access-account-number-input')}
                />
            </div>
        </Modal>
    );
};
