import type { OrderDetail } from '@ab-core/graphql/dist';
import type { MoneyObject } from '@ab-core/types/src';
import type { OrderLineItem } from './mappers/ordersSearchMapper';

export type orderHeadObject = {
    orderDate?: Date;
    deliveryDate?: Date;
    desiredDeliveryDate?: Date;
    orderState: ORDER_STATE;
    orderType: string;
    orderDocumentType?: string;
    orderNumber: string;
    orderText?: string;
    costCenter?: string;
    purchaser?: string;
    totalPrice?: MoneyObject;
    project?: string;
} & Omit<OrderDetail, '__typename'>;

export type MappedSearchOrder = {
    orderNumber: string;
    date?: Date;
    project?: string;
    costCenter?: string;
    type: string;
    state: ORDER_STATE;
    orderText?: string;
    orderer?: string;
    value?: MoneyObject;
    bindingPeriod?: Date;
    lineItemsTotal?: string;
    lineItems?: OrderLineItem[];
};

export enum ORDER_STATE {
    OPEN = 'stateOpen',
    DELIVERED = 'stateDelivered',
    PARTIALLY_DELIVERED = 'statePartiallyDelivered',
    CANCELED = 'stateCanceled',
    RESIDUES = 'stateResidues',
    CREDIT = 'stateCredit',
    UNKNOWN = 'stateUnknown'
}

export enum POSITION_STATE {
    OPEN = 'stateOpen',
    CANCELED = 'stateCanceled',
    DELIVERED = 'stateDelivered',
    RESIDUES = 'stateResidues'
}

export const orderStateMap = {
    1: ORDER_STATE.OPEN,
    2: ORDER_STATE.PARTIALLY_DELIVERED,
    3: ORDER_STATE.DELIVERED,
    4: ORDER_STATE.CANCELED,
    9: ORDER_STATE.RESIDUES,

    999: ORDER_STATE.UNKNOWN
};

export const allowedOrderTypes = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '7',
    '8',
    '9',
    '12',
    '13',
    '14',
    '21',
    '25',
    '26',
    '31',
    '33',
    'G'
];
