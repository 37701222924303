import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const TransferCart: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.617284 0C0.275359 0 0 0.276669 0 0.617284C0 0.957899 0.275359 1.23457 0.617284 1.23457H1.93996H2.11002L2.14905 1.3907L5.78889 15.952C5.00663 16.2554 4.44444 17.0098 4.44444 17.9012C4.44444 19.0588 5.38564 20 6.54321 20C7.69975 20 8.64197 19.0588 8.64197 17.9012C8.64197 17.5907 8.56955 17.2995 8.44714 17.037H13.5273C13.4051 17.2996 13.3333 17.5909 13.3333 17.9012C13.3333 19.0588 14.2745 20 15.4321 20C16.5886 20 17.5309 19.0588 17.5309 17.9012C17.5309 16.7436 16.5886 15.8025 15.4321 15.8025H7.02479L6.46923 13.5802H17.4074C17.6903 13.5802 17.9371 13.3874 18.0062 13.1124L19.9815 5.21099C20.0271 5.02747 19.9867 4.83121 19.869 4.68129C19.7519 4.53217 19.4871 4.44444 19.2969 4.44444L19 4.5L18.6344 5.55511L18.5915 5.67901L16.9248 12.3457H16.82H6.16546L4.94803 7.48983L4.54032 5.67901L4.50002 5.50004L4.19859 4.44444L4.0919 4.07083L3.71413 2.55936L3.19134 0.467709C3.12228 0.192702 2.87551 0 2.59259 0H0.617284ZM19 5C19 5 19 5 19 5L19 5.0002L19 5.00004V5ZM5.67901 17.9012C5.67901 17.4242 6.06621 17.037 6.54321 17.037C7.0193 17.037 7.40741 17.4243 7.40741 17.9012C7.40741 18.3782 7.0193 18.7654 6.54321 18.7654C6.06621 18.7654 5.67901 18.3782 5.67901 17.9012ZM14.5679 17.9012C14.5679 17.4242 14.9551 17.037 15.4321 17.037C15.9082 17.037 16.2963 17.4243 16.2963 17.9012C16.2963 18.3782 15.9082 18.7654 15.4321 18.7654C14.9551 18.7654 14.5679 18.3782 14.5679 17.9012Z"
                fill={color}
            />
            <path
                d="M6 6.5C5.72386 6.5 5.5 6.72386 5.5 7C5.5 7.27614 5.72386 7.5 6 7.5V6.5ZM16.3536 7.35355C16.5488 7.15829 16.5488 6.84171 16.3536 6.64645L13.1716 3.46447C12.9763 3.2692 12.6597 3.2692 12.4645 3.46447C12.2692 3.65973 12.2692 3.97631 12.4645 4.17157L15.2929 7L12.4645 9.82843C12.2692 10.0237 12.2692 10.3403 12.4645 10.5355C12.6597 10.7308 12.9763 10.7308 13.1716 10.5355L16.3536 7.35355ZM6 7.5H16V6.5H6V7.5Z"
                fill={color}
            />
        </SVG>
    );
};
