import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const OFFER = gql`
    query Offer($offerNumber: String!) {
        offer(offerNumber: $offerNumber) {
            ...OfferDetailFields
        }
    }
    ${FRAGMENTS.OFFER_DETAIL_FIELDS}
`;
