import { Colors } from '@ab-core/colors';
import { SPACING } from '@ab-core/spacing';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const style = css`
    color: inherit;
    text-decoration: none;
    .link-icon {
        align-self: center;
    }
    &.ab-link-variant-default {
        color: ${Colors.primary};
        text-decoration: underline;
        display: inline-flex;
        flex-direction: row;
        gap: ${SPACING.SMALL};
        &:hover {
            color: ${Colors.primaryDarkenOne};
            text-decoration: underline;
        }
        &:active {
            color: ${Colors.primaryDarkenTwo};
        }
    }
    &.ab-link-variant-link-only {
        .link-icon {
            display: none;
        }
    }
`;

export const StyledLink = styled.a`
    ${style}
`;

export const StyledGatsbyLink = styled(Link)`
    ${style}
`;
