import type { Mutation, MutationSetShopAccessArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SET_SHOP_ACCESS } from './gql/setShopAccess';

type MutationDataType = Pick<Mutation, 'setShopAccess'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('setShopAccess');

export const useSetShopAccess = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [setShopAccess, { loading, error, data }] = useMutation<MutationDataType, MutationSetShopAccessArgs>(
        SET_SHOP_ACCESS,
        {
            onError
        }
    );

    const outgoingSetShopAccess = useCallback(
        (args: MutationSetShopAccessArgs) => {
            setShopAccess({ variables: { ...args } });
        },
        [setShopAccess]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        setShopAccess: outgoingSetShopAccess,
        loading,
        response
    };
};
