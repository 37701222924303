import { gql } from '@apollo/client';

const ACCOUNT_DATA = gql`
    fragment accountFields on Account {
        accountId
        company
        customerId
        customerClassification
        noSurchargeCut
        cocontractors
        lock
        businessUnit
        isGhostAccess
        hasAbakus
        tecselectPoints
        collectCredits
        outstandingCredits
        expireCredits
        availableCredits
        hasShippingCosts
        hasForeignVatType
        hasNoPromotionPrice
        responsibleSubsidiary
        shippingAddresses {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
            defaultShippingInstructions
        }
    }
`;

export default ACCOUNT_DATA;
