import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { Container } from '@ab-core/grid';
import { Icon } from '@ab-core/icon';
import { SPACING } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

export const FooterContainer = styled(Container)`
    z-index: ${Z_INDEX.FIXED};
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 2px solid ${Colors.backgroundShade};
    background-color: ${Colors.white};
    margin-top: auto;
    padding-top: 20px;
    padding-bottom: 80px;

    @media ${BreakpointsMinQuery.sm} {
        padding-top: 40px;
    }

    @media ${BreakpointsMinQuery.md} {
        padding: 0 40px;
        padding-bottom: 80px;
        justify-content: space-between;
        flex-direction: row;
        min-height: 100px;
        align-items: center;
    }

    @media ${BreakpointsMinQuery.lg} {
        padding-bottom: 0;
    }
`;

export const LogoButton = styled.button`
    color: ${Colors.primary};
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    text-transform: none;
    text-align: left;
    background: ${Colors.white};
    border: 0;
    padding: 0;
    margin: 0;
    height: 100%;
    padding-bottom: 20px;
    @media ${BreakpointsMinQuery.md} {
        padding-bottom: 0;
    }
`;

export const LogoSizer = styled.div`
    width: auto;
    height: 20px;
`;

export const SocialMediaFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin: 0 -10px;
    span {
        padding: 0 10px 10px 10px;
    }
    @media ${BreakpointsMinQuery.sm} {
        flex-wrap: nowrap;
        padding-bottom: 20px;
        padding-left: 40px;
        span {
            padding-bottom: 0;
        }
    }
    @media ${BreakpointsMinQuery.md} {
        padding-right: 40px;
        padding-bottom: 0;
    }
    @media ${BreakpointsMinQuery.lg} {
        padding-right: 40px;
        span {
            padding: 0 18px;
        }
    }
`;

export const IconFooter = styled(Icon)`
    width: 36px;
    height: 36px;

    @media ${BreakpointsMinQuery.lg} {
        padding-bottom: 0;
    }
`;

export const LinksFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: -20px;
    @media ${BreakpointsMinQuery.sm} {
        margin-left: auto;
        flex-direction: row;
        align-items: center;
    }
    @media ${BreakpointsMinQuery.md} {
        margin-left: -20px;
    }
    a:last-child {
        width: 200px;
    }
`;

export const StyledLinkText = styled.div`
    text-decoration: underline;
    padding: 0 0 ${SPACING.SMALL_5} 0;
    @media ${BreakpointsMinQuery.sm} {
        padding: ${SPACING.SMALL_5} ${SPACING.SMALL_3};
    }
`;

export const LeftFooter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    @media ${BreakpointsMinQuery.sm} {
        flex-direction: row;
    }
`;
