import type { Query, QueryGetContactByIdArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_CONTACT_BY_ID } from './gql/getContactById';

type QueryDataType = Pick<Query, 'getContactById'>;
type OutgoingDataType = QueryDataType['getContactById'];
type IncomingDataType = { skip?: boolean; contactId?: string };

const onError = getErrorLoggerFunction('getContactById');

export const useGetContactById = (props: IncomingDataType) => {
    const { skip = false, contactId = '' } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const isSkip = skip || contactId === '';

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryGetContactByIdArgs>(GET_CONTACT_BY_ID, {
        variables: {
            contactId
        },
        skip: isSkip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getContactById);
        }
    }, [data]);

    return {
        contact: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
