import type { WithBreakpointProps } from '@ab-core/breakpoints';
import { SPACING } from '@ab-core/spacing';
import type { WithTestId } from '@ab-core/testing';
import type React from 'react';

export enum SpacerMapper {
    zero = 0,
    xs = SPACING.SMALL_5,
    sm = SPACING.MEDIUM_2,
    md = SPACING.MEDIUM_3,
    lg = SPACING.LARGE,
    xl = SPACING.LARGE_3,
    xxl = SPACING.LARGE_4
}

export type SpacerSizes = 'zero' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type SpacerStyleProps = WithBreakpointProps<SpacerSizes> & { size: SpacerSizes };

export type SpacerProps = WithTestId &
    WithBreakpointProps<SpacerSizes> &
    React.ComponentPropsWithoutRef<'div'> & {
        size?: SpacerSizes;
    };
