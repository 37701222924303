import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const HeadlineWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${SPACING.SMALL_3};
`;

export const IconButton = styled.div`
    cursor: pointer;
    padding: ${SPACING.SMALL_2};
`;
