import type { Mutation, MutationDeleteCostCenterArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_COST_CENTER } from './gql/deleteCostCenter';
import { GET_COST_CENTERS } from './gql/getCostCenters';

type MutationDataType = Pick<Mutation, 'deleteCostCenter'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteCostCenter');

const refetchQueries = [GET_COST_CENTERS];

export const useDeleteCostCenter = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteCostCenter, { loading, error, data }] = useMutation<MutationDataType, MutationDeleteCostCenterArgs>(
        DELETE_COST_CENTER,
        {
            onError,
            refetchQueries
        }
    );

    const outgoingDeleteCostCenter = useCallback(
        (id: MutationDeleteCostCenterArgs['id']) => {
            deleteCostCenter({ variables: { id } });
        },
        [deleteCostCenter]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteCostCenter: outgoingDeleteCostCenter,
        loading,
        response
    };
};
