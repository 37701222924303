import type { Mutation, MutationCreateUserSelfOnboardingArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_USER_SELF_ONBOARDING } from './gql/createUserSelfOnboarding';

type MutationDataType = Pick<Mutation, 'createUserSelfOnboarding'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createUserSelfOnboarding');

export const useCreateUserSelfOnboarding = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createUserSelfOnboarding, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateUserSelfOnboardingArgs
    >(CREATE_USER_SELF_ONBOARDING, {
        onError
    });

    const outgoingCreateUserSelfOnboarding = useCallback(
        (accountInput: MutationCreateUserSelfOnboardingArgs['accountInput']) => {
            createUserSelfOnboarding({ variables: { accountInput, createUser: true } });
        },
        [createUserSelfOnboarding]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createUserSelfOnboarding: outgoingCreateUserSelfOnboarding,
        loading,
        response
    };
};
