import { Colors, Shadows } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const AccordionWrapper = styled.div`
    box-shadow: ${Shadows.componentShadow};
    border-radius: ${RADIUS.ROUNDED};
    overflow: hidden;
`;

export const Section = styled.div`
    display: flex;
    padding: ${SPACING.SMALL_4};
    justify-content: space-between;
    cursor: pointer;
    background-color: ${Colors.backgroundShade};
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${Colors.gray30};
    }

    &.ab-accordion-section-disabled {
        background-color: ${Colors.white};
        cursor: not-allowed;
        color: ${Colors.gray50};
    }
`;

export const Panel = styled.div`
    padding: ${SPACING.SMALL_4};
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
    background-color: ${Colors.white};
    transition: all 0.3s ease-out;
    animation-duration: 0.7s;
    height: 0;
    opacity: 0;

    &.ab-accordion-panel-open {
        height: auto;
        opacity: 1;
        padding-top: ${SPACING.SMALL_4};
        padding-bottom: ${SPACING.SMALL_4};
    }
`;
