import type { Mutation, MutationInviteUserArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { INVITE_USER } from './gql/inviteUser';

type MutationDataType = Pick<Mutation, 'inviteUser'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('inviteUser');

export const useInviteUser = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [inviteUser, { loading, error, data }] = useMutation<MutationDataType, MutationInviteUserArgs>(INVITE_USER, {
        onError
    });

    const outgoingInviteUser = useCallback(
        (contactInput: MutationInviteUserArgs['contactInput']) => {
            inviteUser({ variables: { contactInput } });
        },
        [inviteUser]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        inviteUser: outgoingInviteUser,
        loading,
        response
    };
};
