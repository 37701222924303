import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const GET_KANBAN_LABEL_SETS = gql`
    query GetKanbanLabelSets {
        getKanbanLabelSets {
            ...KanbanLabelSetFields
        }
    }
    ${FRAGMENTS.KANBAN_LABEL_SET}
`;
