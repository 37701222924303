import { makeVar, useReactiveVar } from '@apollo/client';
import type { RefObject } from 'react';
import type { NavigationStoreType, StateType } from './types';

const _state: StateType = {
    userNavigation: makeVar<boolean>(false),
    userDropdownNavigation: makeVar<boolean>(false),
    shopNavigation: makeVar<boolean>(false),
    collectionNavigation: makeVar<boolean>(false),
    collectionButtonRef: makeVar<RefObject<HTMLDivElement> | undefined>(undefined),
    shoppingListFilter: makeVar<string | undefined>(undefined)
};

export const navigationStore: NavigationStoreType = {
    getters: {
        useUserNavigation: () => useReactiveVar(_state.userNavigation),
        useUserDropdownNavigation: () => useReactiveVar(_state.userDropdownNavigation),
        useShopNavigation: () => useReactiveVar(_state.shopNavigation),
        useCollectionNavigation: () => useReactiveVar(_state.collectionNavigation),
        useCollectionButtonRef: () => useReactiveVar(_state.collectionButtonRef),
        useShoppingListFilter: () => useReactiveVar(_state.shoppingListFilter)
    },
    setters: {
        setUserNavigation: (active) => _state.userNavigation(active),
        setUserDropdownNavigation: (active) => _state.userDropdownNavigation(active),
        setShopNavigation: (active) => _state.shopNavigation(active),
        setCollectionNavigation: (active) => _state.collectionNavigation(active),
        setCollectionButtonRef: (ref) => _state.collectionButtonRef(ref),
        setShoppingListFilter: (url) => _state.shoppingListFilter(url)
    }
};
