import { gql } from '@apollo/client';

export const CREATE_ORDER_FROM_OFFER = gql`
    mutation CreateOrderFromOffer($offerId: String, $credits: Float, $coupon: CouponInput) {
        createOrderFromOffer(offerId: $offerId, credits: $credits, coupon: $coupon) {
            id
            orderNumber
            custom {
                customFieldsRaw {
                    name
                    value
                }
            }
        }
    }
`;
