import { gql } from '@apollo/client';

export const SHOPPING_LISTS = gql`
    query ShoppingLists($sort: String) {
        shoppingLists(sort: $sort) {
            results {
                id
                name
                lastModifiedAt
                slug
                shareState
                createdBy {
                    contactId
                    name
                }
                lineItems {
                    id
                }
            }
        }
    }
`;
