import type { Mutation, MutationUpdateShoppingListLineItemArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SHOPPING_LISTS } from './gql/shoppingLists';
import { UPDATE_SHOPPING_LIST_LINE_ITEM } from './gql/updateShoppingListLineItem';
import { SHOPPING_LIST } from './gql/shoppingList';

type MutationDataType = Pick<Mutation, 'updateShoppingListLineItem'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateShoppingListLineItem');

const refetchQueries = [SHOPPING_LISTS, SHOPPING_LIST];

export const useUpdateShoppingListLineItem = (id: MutationUpdateShoppingListLineItemArgs['id']) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateShoppingListLineItem, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpdateShoppingListLineItemArgs
    >(UPDATE_SHOPPING_LIST_LINE_ITEM, {
        refetchQueries,
        onError
    });

    const outgoingUpdateShoppingListLineItem = useCallback(
        (lineItemInput: MutationUpdateShoppingListLineItemArgs['lineItemInput']) => {
            updateShoppingListLineItem({ variables: { id, lineItemInput } });
        },
        [updateShoppingListLineItem]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateShoppingListLineItem: outgoingUpdateShoppingListLineItem,
        loading,
        response
    };
};
