import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const GET_KANBAN_LABEL_SET = gql`
    query GetKanbanLabelSet($id: String!) {
        getKanbanLabelSet(id: $id) {
            ...KanbanLabelSetFields
        }
    }
    ${FRAGMENTS.KANBAN_LABEL_SET}
`;
