import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import React from 'react';
import { IconWrapper, InputContainer } from './styled';

export type BasicInputProps = WithTestId &
    React.ComponentPropsWithoutRef<'input'> & {
        label?: string;
        maxLength?: number;
        error?: boolean;
        icon?: {
            name?: IconProps['name'];
            action?: () => void;
            id?: string;
        };
    };

export const BasicInput = React.forwardRef<HTMLInputElement, BasicInputProps>((props, ref) => {
    const { label, maxLength, error = false, icon, className, value, ...rest } = props;

    const hasIcon = icon !== undefined && icon.name !== undefined && icon.name;
    const inputClass = getClassNames({
        className,
        prefix: 'input',
        modifier: { error, hasIcon }
    });
    const labelClass = getClassNames({
        prefix: 'label',
        modifier: { error }
    });

    const labelSpanClass = getClassNames({
        prefix: 'label-span'
    });

    return (
        <InputContainer>
            <input {...rest} ref={ref} className={inputClass} placeholder={label} maxLength={maxLength} value={value} />

            {label && (
                <label className={labelClass + ' text-truncate'}>
                    <div className="pseudoElementLine" />
                    <span className={labelSpanClass}>{label}</span>
                </label>
            )}
            {hasIcon && icon.name && (
                <IconWrapper onClick={icon.action} {...getTestIdProp(icon.id || '')}>
                    <Icon size="SMALL_4" color={error ? 'error' : 'gray50'} name={icon.name} />
                </IconWrapper>
            )}
        </InputContainer>
    );
});
