import { gql } from '@apollo/client';

export const UPDATE_SHOPPING_SHARE_STATE = gql`
    mutation UpdateShoppingShareState($id: String!, $shareState: String!) {
        updateShoppingShareState(id: $id, shareState: $shareState) {
            id
            name
        }
    }
`;
