import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const GET_ACCOUNTS = gql`
    query GetAccounts {
        getAccounts {
            ...accountFields
        }
    }
    ${FRAGMENTS.ACCOUNT_DATA}
`;
