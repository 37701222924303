import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const UPDATE_KANBAN_LABEL = gql`
    mutation UpdateKanbanLabel($data: KanbanLabelUpdateInput!) {
        updateKanbanLabel(data: $data) {
            ...KanbanLabelFields
        }
    }
    ${FRAGMENTS.KANBAN_LABEL}
`;
