import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { OFFERS } from './gql/offers';

type QueryDataType = Pick<Query, 'offers'>;
type OutgoingDataType = QueryDataType['offers'];

const onError = getErrorLoggerFunction('offers');

export const useOffers = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(OFFERS, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.offers || []);
        }
    }, [data]);

    return {
        offers: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
