import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_COST_CENTERS } from './gql/getCostCenters';

type QueryDataType = Pick<Query, 'getCostCenters'>;
type OutgoingDataType = QueryDataType['getCostCenters'];

const onError = getErrorLoggerFunction('getCostCenters');

export const useGetCostCenters = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_COST_CENTERS, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getCostCenters);
        }
    }, [data]);

    return {
        costCenters: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
