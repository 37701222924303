import type { Mutation, MutationSetOfferInformationArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { OFFER } from './gql/offer';
import { SET_OFFER_INFORMATION } from './gql/setOfferInformation';

type MutationDataType = Pick<Mutation, 'setOfferInformation'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('setOfferInformation');

const refetchQueries = [OFFER];

export const useSetOfferInformation = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [setOfferInformation, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSetOfferInformationArgs
    >(SET_OFFER_INFORMATION, {
        refetchQueries,
        onError
    });

    const outgoingSetOfferInformation = useCallback(
        (variables: MutationSetOfferInformationArgs) => {
            setOfferInformation({ variables });
        },
        [setOfferInformation]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        setOfferInformation: outgoingSetOfferInformation,
        loading,
        response
    };
};
