import type { Query, QueryMaxCreditsArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { MAX_CREDITS } from './gql/maxCredits';

type QueryDataType = Pick<Query, 'maxCredits'>;
type OutgoingDataType = QueryDataType['maxCredits'];

const onError = getErrorLoggerFunction('maxCredits');

export const useMaxCredits = (valueWithoutAdditional: QueryMaxCreditsArgs['valueWithoutAdditional']) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryMaxCreditsArgs>(MAX_CREDITS, {
        variables: { valueWithoutAdditional },
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.maxCredits);
        }
    }, [data]);

    return {
        maxCredits: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
