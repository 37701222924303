import type { Query, QueryGetCustomerDataArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_CUSTOMER_DATA } from './gql/getCustomerData';

type QueryDataType = Pick<Query, 'getCustomerData'>;
type OutgoingDataType = QueryDataType['getCustomerData'];
type IncomingDataType = { force?: boolean; skip?: boolean };

const onError = getErrorLoggerFunction('getCustomerData');

export const useGetCustomerData = (props: IncomingDataType) => {
    const { skip = false, force = false } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error, client } = useQuery<QueryDataType, QueryGetCustomerDataArgs>(
        GET_CUSTOMER_DATA,
        {
            variables: {
                force
            },
            skip,
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getCustomerData);
        }
    }, [data]);

    return {
        customer: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch,
        client
    };
};
