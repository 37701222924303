import { userHasPermissions } from '@ab-core-functions/user-permission';
import type { FC } from 'react';
import React from 'react';
import { NavigationElement } from './navigation';
import { TitleElement } from './title';
import type { ContentItem } from './types';
import { navigation } from './utils/elementsObjects';

type ShopElementsProps = {
    pathName: string;
    onClose?: () => void;
};

export const ShopElements: FC<ShopElementsProps> = (props) => {
    const { pathName, onClose } = props;
    const filterNavigation = navigation.map((item) => {
        return {
            ...item,
            content: item.content.filter((right: ContentItem) =>
                !right?.permissionName ? true : userHasPermissions(right?.permissionName)
            )
        };
    });

    return (
        <div className="my-medium">
            {filterNavigation?.map(
                (section, index) =>
                    section.content.length > 0 && (
                        <React.Fragment key={index}>
                            <TitleElement headline={section.headline} />
                            <NavigationElement content={section.content} pathName={pathName} onClose={onClose} />
                        </React.Fragment>
                    )
            )}
        </div>
    );
};
