import type { Mutation, MutationUpdateCostCenterArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_COST_CENTERS } from './gql/getCostCenters';
import { UPDATE_COST_CENTER } from './gql/updateCostCenter';

type MutationDataType = Pick<Mutation, 'updateCostCenter'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateCostCenter');

const refetchQueries = [GET_COST_CENTERS];

export const useUpdateCostCenter = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateCostCenter, { loading, error, data }] = useMutation<MutationDataType, MutationUpdateCostCenterArgs>(
        UPDATE_COST_CENTER,
        {
            onError,
            refetchQueries
        }
    );

    const outgoingUpdateCostCenter = useCallback(
        (args: MutationUpdateCostCenterArgs['data']) => {
            updateCostCenter({ variables: { data: args } });
        },
        [updateCostCenter]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateCostCenter: outgoingUpdateCostCenter,
        loading,
        response
    };
};
