import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import styled from 'styled-components';

export const LoaderPositioner = styled.div`
    position: relative;
`;

export const Loader = styled.div`
    position: absolute;
    display: inline-block;
    width: 100%;
    top: 0;
    left: 0;
    height: 5px;
    background: ${Colors.gray30};
    border-radius: ${RADIUS.ROUNDED} ${RADIUS.ROUNDED} 0 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    :after {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        height: 100%;
        animation: load 3s infinite;
    }

    @keyframes load {
        0% {
            width: 0;
            background: ${Colors.primary};
        }

        100% {
            width: 100%;
            background: ${Colors.primary};
        }
    }
`;
