import type { Query, QueryOfferArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { OFFER } from './gql/offer';

type QueryDataType = Pick<Query, 'offer'>;
type OutgoingDataType = QueryDataType['offer'];

const onError = getErrorLoggerFunction('offer');

export const useOffer = (offerNumber = '') => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = offerNumber === '';

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryOfferArgs>(OFFER, {
        variables: {
            offerNumber
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.offer);
        }
    }, [data]);

    return {
        offer: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
