import type { Mutation, MutationTransferAllLineItemsArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { TRANSFER_ALL_LINE_ITEMS } from './gql/transferAllLineItems';

type MutationDataType = Pick<Mutation, 'transferAllLineItems'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('transferAllLineItems');

const refetchQueries = ['getSharedCarts', 'getMyCarts', 'getCarts', 'getActiveCart'];

export const useTransferAllLineItems = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [transferAllLineItems, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationTransferAllLineItemsArgs
    >(TRANSFER_ALL_LINE_ITEMS, {
        onError,
        refetchQueries
    });

    const outgoingTransferAllLineItems = useCallback(
        (
            sourceCartId: MutationTransferAllLineItemsArgs['sourceCartId'],
            targetCartId: MutationTransferAllLineItemsArgs['targetCartId']
        ) => {
            transferAllLineItems({ variables: { sourceCartId, targetCartId } });
        },
        [transferAllLineItems]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        transferAllLineItems: outgoingTransferAllLineItems,
        loading,
        response
    };
};
