import { gql } from '@apollo/client';

export const SHOPPING_LIST = gql`
    query ShoppingList($id: String!) {
        shoppingList(id: $id) {
            id
            name
            slug
            lastModifiedAt
            shareState
            createdBy {
                contactId
                name
            }
            lineItems {
                id
                sku
                quantity
                image
                productName
                ean
                supplierId
                supplierName
                productCocontractorValue
                productCocontractor
                procuredProduct
                salesUnit
                quantityUnit
                isTecselect
                isAbakusPlus
                customerArticleNumber
                hasAccessories
                hasAlternatives
                promoProduct
                scoreCardIndex
            }
        }
    }
`;
