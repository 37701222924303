import { useOffers } from '@ab-core/hooks-v2';
import { Icon } from '@ab-core/icon';
import Text from '@ab-core/text';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import type { ContentItem } from '../types';

type OfferNavigationElementProps = Omit<ContentItem, 'link'>;

const FALLBACK_DATE = new Date('2022-12-12');

export const OfferNavigationElement: FC<OfferNavigationElementProps> = (props) => {
    const { icon, title } = props;
    const { offers } = useOffers();
    const [showOfferNotification, setShowOfferNotification] = useState(false);

    const checkOffers = offers ? [...offers] : [];

    useEffect(() => {
        setShowOfferNotification(false);
        const newOfferNotificationString = localStorage.getItem('offersRead');
        let newOfferNotification: Date;

        if (newOfferNotificationString) {
            const parsedDate = new Date(newOfferNotificationString);

            newOfferNotification = isNaN(parsedDate.getTime()) ? FALLBACK_DATE : parsedDate;
        } else {
            newOfferNotification = FALLBACK_DATE;
        }

        const shouldShowNotification = offers?.some((offer) => {
            const offerDate = new Date(offer.lastModifiedAt ?? Date.now());

            return offerDate.getTime() >= newOfferNotification.getTime();
        });

        if (shouldShowNotification) {
            setShowOfferNotification(true);
        }
    }, [checkOffers]);

    return (
        <>
            <Icon name={showOfferNotification ? 'LabelActive' : icon} />
            <Text>{title}</Text>
        </>
    );
};
