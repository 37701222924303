import {
    isGhostAccess as isGhostAccessFunction,
    userCanHidePrices,
    userCanSetGhost,
    userHasOnlySalesView
} from '@ab-core-functions/user-permission';
import { BREAKPOINT } from '@ab-core/breakpoints';
import { PermissionWrapper, ROUTES, formatNumber, logOut, useIsMobile } from '@ab-core/functions';
import { isSalesViewEnabled, toggleSalesView } from '@ab-core/functions/salesView';
import { useAuth } from '@ab-core/hooks';
import { useDeleteGhostAccess, useGetAccounts, useGetCustomerData } from '@ab-core/hooks-v2';
import { useSetActiveCart } from '@ab-core/hooks/cartNew/activeCart/checkForActiveCart';
import useAccountContactRelation from '@ab-core/hooks/user/useAccountContactRelation';
import { HorizontalLine } from '@ab-core/horizontalline';
import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import { LINK_VARIANT, Link } from '@ab-core/link';
import { Loader } from '@ab-core/loader';
import useText from '@ab-core/localization/src/useText';
import { modalOpenState } from '@ab-core/seo/src/state/modalOpenState';
import { globalStore, navigationStore } from '@ab-core/store';
import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import Text from '@ab-core/text';
import { toast } from '@ab-core/toast';
import { useReactiveVar } from '@apollo/client';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { FavoriteToggle } from './components';
import { Element } from './index.styled';
import { ChangeLogNotifications } from './notifications';

type DropDownProps = {
    redirectAfterLogin?: boolean;
};

type InteractionElementProps = WithTestId & {
    onClick: () => void;
    iconName: IconProps['name'];
    content: string;
};

const InteractionElement: FC<InteractionElementProps> = ({ onClick, iconName, content, ...rest }) => {
    return (
        <Element {...rest} className="flex gap-small2 flex-align-items pointer" onClick={onClick}>
            <Icon name={iconName} />
            <Text content={content} />
        </Element>
    );
};

export const UserSettings: FC<DropDownProps> = () => {
    const [displaySalesView, setDisplaySalesView] = useState(false);
    let stateModalOpen = useReactiveVar(modalOpenState) || false;
    const hasGhostAccess = userCanSetGhost();
    const { user } = useAuth();

    const { accounts } = useGetAccounts({
        accountIdForSort: user?.activeAccount?.accountId,
        skip: !user?.activeAccount?.accountId
    });

    const { deleteGhostAccess, success: successDeleted } = useDeleteGhostAccess();
    const { refetch } = useGetCustomerData({ skip: true });

    const { checkForActiveCart } = useSetActiveCart();
    const { updateAccountContactRelation, client, pending } = useAccountContactRelation();
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const hasSalesViewPermission = userCanHidePrices();
    const linkToGhostAccessText = useText('ghostAccess.linkToModal');
    const linkLeaveGhostAccessText = useText('ghostAccess.linkLeaveGhostAccess');

    const isGhostAccess = isGhostAccessFunction();

    const updateDisplayView = () => {
        toggleSalesView(user?.activeAccount?.accountNumber || '');
        const showView = isSalesViewEnabled(user?.activeAccount?.accountNumber || '');
        setDisplaySalesView(showView);

        if (showView) {
            toast.info('Einkaufspreis Änderung', 'Der Einkaufspreis wurde ausgeblendet');
        } else {
            toast.info('Einkaufspreis Änderung', 'Der Einkaufspreis wurde eingeblendet');
        }
    };

    const setModalOpen = (openModal: boolean) => {
        stateModalOpen = openModal;
        const newState = JSON.parse(JSON.stringify(stateModalOpen));
        modalOpenState(newState);
    };

    const handleChangeAccount = () => {
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);

        setModalOpen(true);
    };

    const leaveGhostAccess = () => {
        deleteGhostAccess();
    };

    const triggerLogout = () => {
        deleteGhostAccess();
        logOut();
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
    };

    const triggerHome = () => {
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
        navigate(ROUTES.SHOP);
    };

    const triggerFaq = () => {
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
        navigate(ROUTES.FAQ);
    };

    const triggerHelp = () => {
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
        navigate(ROUTES.HELP);
    };

    const triggerCredits = () => {
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
        navigate(ROUTES.CREDIT_INFO);
    };

    const triggerGhost = () => {
        navigationStore.setters.setUserNavigation(false);
        globalStore.setters.setGhostAccess(true);
    };

    useEffect(() => {
        const showView = isSalesViewEnabled(user?.activeAccount?.accountNumber || '');
        setDisplaySalesView(showView);
    }, []);

    useEffect(() => {
        if (successDeleted) {
            if (accounts && accounts[1] && accounts[1].accountId) {
                updateAccountContactRelation({
                    variables: {
                        accountContactRelationInput: {
                            accountId: accounts[1].accountId
                        }
                    }
                });
            }

            const dataRefetch = async () => {
                await refetch({ force: true });
                await client.resetStore();
                await checkForActiveCart();
                await navigate(ROUTES.SHOP);
            };
            dataRefetch();
        }
    }, [successDeleted]);
    const userHasOnlySalesViewRight = userHasOnlySalesView();

    return (
        <>
            {pending && <Loader />}
            <Text
                className="px-small2 py-small"
                {...getTestIdProp('account-number')}
                content={`Kundennummer: ${user?.activeAccount?.accountNumber}`}
            />
            <Text className="px-small2 py-small" content={`Benutzername: ${user?.userName}`} />
            <Text className="px-small2 py-small" content={user?.activeAccount?.accountName} />
            {user && (
                <div className="flex gap-medium px-small2 py-small">
                    {user?.hasAbakus && (
                        <div className="flex gap-small2 flex-align-items">
                            <Icon name="Abakus" color="primary" />
                            <Text color="black" content="Abakus" />
                        </div>
                    )}
                    <PermissionWrapper permission="canSeeTecselect">
                        <div className="flex gap-small2 flex-align-items">
                            <Icon name="Tecselect" color="primary" />
                            <Text color="black">{formatNumber(user?.tecselectPoints)}</Text>
                        </div>
                    </PermissionWrapper>
                </div>
            )}
            {user && (
                <PermissionWrapper permission="creditsSee">
                    <InteractionElement
                        {...getTestIdProp('account-credits')}
                        content={`${formatNumber(user?.availableCredits)}`}
                        iconName="Credits"
                        onClick={triggerCredits}
                    />
                </PermissionWrapper>
            )}
            {(accounts?.length || [].length) > 1 && !isGhostAccess && (
                <InteractionElement
                    {...getTestIdProp('switch-account-button')}
                    iconName="RotatingArrow"
                    content="Konto wechseln"
                    onClick={handleChangeAccount}
                />
            )}
            {isGhostAccess && (
                <InteractionElement
                    {...getTestIdProp('leave-ghost-access-button')}
                    iconName="Ghost"
                    content={linkLeaveGhostAccessText}
                    onClick={leaveGhostAccess}
                />
            )}
            {hasGhostAccess && !isGhostAccess && (
                <InteractionElement
                    {...getTestIdProp('open-ghost-access-button')}
                    iconName="Ghost"
                    content={linkToGhostAccessText}
                    onClick={triggerGhost}
                />
            )}
            {hasSalesViewPermission && isMobile && !userHasOnlySalesViewRight && (
                <InteractionElement
                    {...getTestIdProp('hide-show-prices-button')}
                    iconName={displaySalesView ? 'NotVisible' : 'Visible'}
                    onClick={updateDisplayView}
                    content={displaySalesView ? 'Preise ausblenden' : 'Preise einblenden'}
                />
            )}
            <br />
            <HorizontalLine />
            <br />
            <InteractionElement
                {...getTestIdProp('go-to-my-shop-button')}
                iconName="Home"
                content="Mein Shop"
                onClick={triggerHome}
            />
            <Element>
                <Link
                    variant={LINK_VARIANT.LINK_ONLY}
                    to={'promotion/ansprechpartner/'}
                    target="_blank"
                    className="flex gap-small2 pointer"
                >
                    <Icon name="Support" />
                    <Text>Ansprechpartner</Text>
                </Link>
            </Element>
            <InteractionElement iconName="Faq" content="FAQ" onClick={triggerFaq} />
            {!isMobile && <InteractionElement iconName="Faq" content="Hilfe" onClick={triggerHelp} />}
            <Element>
                <ChangeLogNotifications />
            </Element>
            {user && (
                <Element>
                    <FavoriteToggle />
                </Element>
            )}
            <br />
            <HorizontalLine />
            <br />
            <InteractionElement
                {...getTestIdProp('logout-button')}
                iconName="Logout"
                content="Abmelden"
                onClick={triggerLogout}
            />
        </>
    );
};
