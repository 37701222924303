import type { Mutation, MutationSetPromotionalSheetArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SET_PROMOTIONAL_SHEET } from './gql/setPromotionalSheet';

type MutationDataType = Pick<Mutation, 'setPromotionalSheet'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('setPromotionalSheet');

export const useSetPromotionalSheet = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [setPromotionalSheet, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSetPromotionalSheetArgs
    >(SET_PROMOTIONAL_SHEET, {
        onError
    });

    const outgoingSetPromotionalSheet = useCallback(
        (promotionalSheetInput: MutationSetPromotionalSheetArgs['promotionalSheetInput']) => {
            setPromotionalSheet({ variables: { promotionalSheetInput } });
        },
        [setPromotionalSheet]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        setPromotionalSheet: outgoingSetPromotionalSheet,
        loading,
        response
    };
};
