import type { Mutation, MutationUpsertShoppingListLineItemArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { UPSERT_SHOPPING_LIST_LINE_ITEM } from './gql/upsertShoppingListLineItem';

type MutationDataType = Pick<Mutation, 'upsertShoppingListLineItem'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('upsertShoppingListLineItem');

export const useUpsertShoppingListLineItem = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [upsertShoppingListLineItem, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpsertShoppingListLineItemArgs
    >(UPSERT_SHOPPING_LIST_LINE_ITEM, {
        onError
    });

    const outgoingUpsertShoppingListLineItem = useCallback(
        (variables: MutationUpsertShoppingListLineItemArgs) => {
            upsertShoppingListLineItem({ variables });
        },
        [upsertShoppingListLineItem]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        upsertShoppingListLineItem: outgoingUpsertShoppingListLineItem,
        loading,
        response
    };
};
