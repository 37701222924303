import { Shadows } from '@ab-core/colors';
import { FormElementHeight } from '@ab-core/forms';
import { RADIUS } from '@ab-core/radius';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

export const List = styled.div`
    position: absolute;
    width: 100%;
    z-index: ${Z_INDEX.TOOLTIP};
    max-height: 200px;
    height: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    box-shadow: ${Shadows.componentShadow};
    border-bottom-left-radius: ${RADIUS.MINIMAL};
    border-bottom-right-radius: ${RADIUS.MINIMAL};

    ::-webkit-scrollbar {
        width: 2px;
    }

    &.ab-dropdown-list-position-top {
        bottom: ${FormElementHeight.Normal};
    }
`;
