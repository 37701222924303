import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const ElementWrapper = styled.div`
    display: flex;
    gap: ${SPACING.SMALL_2};
    padding: ${SPACING.SMALL_5};
    justify-content: center;
    align-items: center;
    width: auto;
    cursor: pointer;
    @media ${BreakpointsMinQuery.lg} {
        padding: ${SPACING.MEDIUM_2};
    }
`;
