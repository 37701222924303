import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const FavoriteListWrapper = styled.div`
    gap: ${SPACING.SMALL_4};
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: space-between;

    &.ab-favorite-list-wrapper-editable {
        touch-action: none;
    }

    @media ${BreakpointsMinQuery.sm} {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

export const FavoritItemContainer = styled.div<{ order?: number }>`
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-radius: ${RADIUS.ROUNDED};
    border: 1px solid ${Colors.gray70};
    background: ${Colors.white};
    color: ${Colors.black};
    width: 100px;
    height: 100px;
    ${({ order }) =>
        order &&
        `
            order: ${order};
    `}

    &.ab-favorite-item-editable {
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
    }

    &.ab-favorite-dummy-item {
        display: none;
        &.ab-favorite-dummy-item-is-dragging {
            border-style: dashed;
            display: flex;
        }
    }
`;

export const FavoritItemInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SPACING.SMALL_2};
    width: 100%;
    padding: ${SPACING.SMALL_2};

    .ab-favorite-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 40px;
`;
