import type { Offer, OfferPosition, QueryOfferArgs } from '@ab-core/graphql/dist';
import { DeliveryMethod } from '@ab-core/shippingaddress/types';
import { gql, useQuery } from '@apollo/client';
import { OFFER_DETAIL_FRAGMENT } from '../fragments/offerDetailFragment';

export const OFFER_QUERY = gql`
    query OfferOld($offerNumber: String!) {
        offer(offerNumber: $offerNumber) {
            ...OfferDetailFragment
        }
    }
    ${OFFER_DETAIL_FRAGMENT}
`;

type Data = {
    offer: Offer;
};

export type CartOffer = {
    shippingMethod: DeliveryMethod;
    deliveryDate?: Date;
    orderText?: string;
    costCenter?: string;
    singleInv?: boolean;
    priceDN?: boolean;
    shippingInstructions?: string;
    completeDelivery: boolean;
    shareState?: string;
    bindingPeriod?: Date;
    offerDate?: Date;
} & Omit<Offer, 'completeDelivery' | 'singleInvoice' | 'priceDN' | 'bindingPeriod' | 'offerDate'>;

export const useCartOffer = (offerNumber = '') => {
    const { data, loading, error } = useQuery<Data, QueryOfferArgs>(OFFER_QUERY, {
        skip: offerNumber === '',
        variables: {
            offerNumber
        }
    });

    const cartOffer: CartOffer | undefined = data?.offer
        ? {
              ...data?.offer,
              shippingMethod: (data?.offer.shippingInfo?.shippingMethod?.key.toUpperCase() ||
                  DeliveryMethod.PICKUP) as DeliveryMethod,
              deliveryDate: data?.offer?.desiredDeliveryDate
                  ? new Date(Date.parse(data?.offer.desiredDeliveryDate))
                  : undefined,
              displayShippingMethod: data?.offer.displayShippingMethod || DeliveryMethod.DELIVERY,
              orderText: data?.offer.offerText,
              costCenter: data?.offer.costCenter,
              singleInv: data?.offer?.singleInvoice === 'true',
              priceDN: data?.offer?.priceDeliveryNote === 'true',
              shippingInstructions: data?.offer?.shippingInstructions,
              completeDelivery: data?.offer?.completeDelivery === 'true',
              offerDate: data?.offer?.offerDate ? new Date(data?.offer.offerDate) : undefined,
              bindingPeriod: data?.offer?.bindingPeriod ? new Date(data?.offer.bindingPeriod) : undefined
          }
        : undefined;

    let cartOfferLineitems: undefined | Array<OfferPosition>;
    data?.offer?.offerPositionsGroups?.forEach((offerPositionsGroup) => {
        offerPositionsGroup.offerPositions?.forEach((offerPosition) => {
            if (offerPosition.isSelected && offerPosition.positionType === 'P') {
                if (Array.isArray(cartOfferLineitems)) {
                    cartOfferLineitems.push(offerPosition);
                } else {
                    cartOfferLineitems = [offerPosition];
                }
            }
        });
    });

    return {
        cartOfferLineitems,
        cartOffer,
        cartOfferLoading: loading,
        cartOfferError: error
    };
};
