import { ACCOUNT_DATA } from './accountFragment';
import { CART_PRICE_OBJECT } from './cartPriceFragment';
import { KANBAN_LABEL } from './kanbanLabelFragment';
import { KANBAN_LABEL_SET } from './kanbanLabelSetFragment';
import { MONEY_OBJECT } from './moneyObject';
import { OFFER_DETAIL_FIELDS } from './offerDetailFragment';
import { POSITION_PRICE_OBJECT } from './positionPriceObject';
import { PRODUCT_SEARCH } from './productSearchFragment';
import { USER_DATA } from './userFragment';

export const FRAGMENTS = {
    PRODUCT_SEARCH,
    POSITION_PRICE_OBJECT,
    CART_PRICE_OBJECT,
    KANBAN_LABEL,
    KANBAN_LABEL_SET,
    OFFER_DETAIL_FIELDS,
    ACCOUNT_DATA,
    USER_DATA,
    MONEY_OBJECT
};
