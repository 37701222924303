import { userHasPermissions } from '@ab-core-functions/user-permission';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';

type PermissionWrapperProps = WithChildren & {
    permission?: string;
};

const PermissionWrapper: FC<PermissionWrapperProps> = (props) => {
    const { permission, children } = props;
    const checkPermission = (permissionParam: string | undefined) => userHasPermissions(permissionParam || '');

    if (checkPermission(permission) || permission === undefined) {
        return <>{children}</>;
    }

    return null;
};

export default PermissionWrapper;
