import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_PROMOTIONAL_SHEET } from './gql/getPromotionalSheet';

type QueryDataType = Pick<Query, 'getPromotionalSheet'>;
type OutgoingDataType = QueryDataType['getPromotionalSheet'];

const onError = getErrorLoggerFunction('getPromotionalSheet');

const mapper = (getPromotionalSheet: OutgoingDataType): OutgoingDataType => {
    return {
        ...getPromotionalSheet,
        headline: getPromotionalSheet?.headline || 'Unser Angebot für Sie',
        priceColor: getPromotionalSheet?.priceColor || 'schwarz',
        legalNotice: getPromotionalSheet?.legalNotice || 'Nur so lange der Vorrat reicht.'
    };
};

export const useGetPromotionalSheet = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_PROMOTIONAL_SHEET, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(mapper(data.getPromotionalSheet));
        }
    }, [data]);

    return {
        promotionalSheet: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
