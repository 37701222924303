import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const CardHeader = styled.div`
    padding-bottom: ${SPACING.SMALL_2};
    @media ${BreakpointsMinQuery.lg} {
        padding-bottom: ${SPACING.SMALL_4};
    }
`;

export const CardContent = styled.div`
    padding: ${SPACING.SMALL_2} 0;
    @media ${BreakpointsMinQuery.lg} {
        padding: ${SPACING.SMALL_5} 0;
    }
    &.ab-card-list {
        padding: ${SPACING.SMALL_2} 0 0 0;
        @media ${BreakpointsMinQuery.lg} {
            padding: ${SPACING.SMALL_5} 0 0 0;
        }
    }

    border-top: 1px solid ${Colors.gray30};
    margin-bottom: auto;
`;

export const CardActions = styled.div`
    border-top: 1px solid ${Colors.gray30};
    padding-top: ${SPACING.SMALL_2};
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media ${BreakpointsMinQuery.lg} {
        padding-top: ${SPACING.SMALL_4};
    }
`;

export const Card = styled.section`
    display: flex;
    flex-direction: column;
    padding: ${SPACING.SMALL_4};
    &.ab-card-styleType-flat {
        border: 1px solid ${Colors.gray30};
    }
    &.ab-card-styleType-card {
        box-shadow: ${Shadows.componentShadow};
    }
    &.ab-card-active {
        border-left: 6px solid ${Colors.primary};
    }
    &.ab-card-wordbreak {
        word-break: break-all;
    }

    @media ${BreakpointsMinQuery.lg} {
        padding: ${SPACING.MEDIUM};
    }
    width: 100%;
    position: relative;
    background-color: ${Colors.white};
    border-radius: ${RADIUS.ROUNDED};
    margin: ${SPACING.SMALL_2} 0;
`;

export const CardListWrapper = styled.div`
    &.ab-card-pointer {
        cursor: pointer;
    }
`;

export const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
