import type { Mutation, MutationCreateOfferInteractionMailArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_OFFER_INTERACTION_MAIL } from './gql/createOfferInteractionMail';

type MutationDataType = Pick<Mutation, 'createOfferInteractionMail'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createOfferInteractionMail');

export const useCreateOfferInteractionMail = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createOfferInteractionMail, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateOfferInteractionMailArgs
    >(CREATE_OFFER_INTERACTION_MAIL, {
        onError
    });

    const outgoingCreateOfferInteractionMail = useCallback(
        (offerInteractionMailInput: MutationCreateOfferInteractionMailArgs['offerInteractionMailInput']) => {
            createOfferInteractionMail({ variables: { offerInteractionMailInput } });
        },
        [createOfferInteractionMail]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createOfferInteractionMail: outgoingCreateOfferInteractionMail,
        loading,
        response
    };
};
