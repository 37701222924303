import { gql } from '@apollo/client';

export const GET_PROMOTIONAL_SHEET = gql`
    query GetPromotionalSheet {
        getPromotionalSheet {
            headline
            priceColor
            legalNotice
            showPrice
            showUvp
            showVat
            footerText
            companyImage
            lastName
            firstName
            email
            phoneNumber
            showEan
            showProductDescription
            showTechnicalData
        }
    }
`;
