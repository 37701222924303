import { gql } from '@apollo/client';

export const CREATE_SHOPPING_LIST = gql`
    mutation CreateShoppingList($shoppingListInput: ShoppingListInput!) {
        createShoppingList(shoppingListInput: $shoppingListInput) {
            id
            name
        }
    }
`;
