import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const CREATE_KANBAN_LABEL = gql`
    mutation CreateKanbanLabel($labelData: KanbanLabelCreationInput!, $labelSetId: String!) {
        createKanbanLabel(labelData: $labelData, labelSetId: $labelSetId) {
            ...KanbanLabelFields
        }
    }
    ${FRAGMENTS.KANBAN_LABEL}
`;
