import type { Mutation, MutationUpdateShoppingShareStateArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SHOPPING_LISTS } from './gql/shoppingLists';
import { UPDATE_SHOPPING_SHARE_STATE } from './gql/updateShoppingShareState';
import { SHARE_STATE } from './utils';

type MutationDataType = Pick<Mutation, 'updateShoppingShareState'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateShoppingShareState');

const refetchQueries = [SHOPPING_LISTS];

export const useUpdateShoppingShareState = (id: string, shareState = SHARE_STATE.PRIVATE) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateShoppingShareState, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpdateShoppingShareStateArgs
    >(UPDATE_SHOPPING_SHARE_STATE, {
        variables: {
            id,
            shareState
        },
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateShoppingShareState,
        loading,
        response
    };
};
