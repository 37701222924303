import type { Query, QueryShoppingListsArgs, ShoppingList } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SHOPPING_LISTS } from './gql/shoppingLists';
import type { SHARE_STATE } from './utils';
import { getShareStateByString } from './utils';

export enum SORT_OPTIONS {
    LAST_MODIFIED_AT_DESC = 'lastModifiedAt desc',
    NAME_ASC = 'name.DE-de asc',
    NAME_DESC = 'name.DE-de desc'
}

type QueryDataType = Pick<Query, 'shoppingLists'>;
type OutgoingDataType = Array<ShoppingListType> | undefined;
type IncomingDataType = { skip?: boolean; sort?: SORT_OPTIONS };

const onError = getErrorLoggerFunction('shoppingLists');

export type ShoppingListType = Omit<ShoppingList, 'lastModifiedAt' | 'shareState'> & {
    lastModifiedAt: Date;
    shareState?: SHARE_STATE;
};

export const useShoppingLists = (props: IncomingDataType) => {
    const { skip = false, sort = SORT_OPTIONS.LAST_MODIFIED_AT_DESC } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryShoppingListsArgs>(SHOPPING_LISTS, {
        variables: {
            sort
        },
        onError,
        skip
    });

    useEffect(() => {
        if (data?.shoppingLists) {
            const mappedData: Array<ShoppingListType> =
                data.shoppingLists.results?.map((item) => ({
                    ...item,
                    shareState: getShareStateByString(item.shareState),
                    slug: `/liste?name=${item.slug}&id=${item.id}`,
                    lastModifiedAt: new Date(item.lastModifiedAt)
                })) || [];
            setOutgoingData(mappedData);
        }
    }, [data]);

    return {
        shoppingLists: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
