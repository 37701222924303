import { replaceImageUrl } from '@ab-core-functions/cloudinary';
import type { ShoppingListLineItem } from '@ab-core/graphql/dist';
import type { User } from '@ab-core/types/src';

export type MappedLineItem = {
    id: string;
    sku: string;
    productName: string;
    ean: string;
    supplierId: string;
    image: string;
    procuredProduct: boolean;
    productCocontractor: boolean;
    salesUnit: number;
    quantityUnit: string;
    category: string;
    isTecselect: boolean;
    isAbakus: boolean;
    isAbakusPlus: boolean;
    isUnqualifiedContractProduct: boolean;
    hasAccessories: boolean;
    hasAlternatives: boolean;
    alternativesLink?: string;
    accessoriesLink?: string;
    onlineAvailable: boolean;
    promoProduct?: boolean;
    quantity?: number;
    scoreCardIndex?: number;
    positionText?: string;
    reach?: boolean;
    topProduct?: boolean;
    customerArticleNumber?: string;
    name?: string;
    chargeNr?: string;
    packagingSize?: string;
    position?: string;
};

const isUnqualifiedContractProduct = (productCocontractor: boolean, productCocontractorValue: string, user: User) => {
    if (productCocontractor) {
        if (user && user.cocontractors) {
            return !user.cocontractors.includes(productCocontractorValue);
        }

        return true;
    }

    return false;
};

const lineItemMapper = (
    data: Array<ShoppingListLineItem>,
    user?: User,
    userHasTecSelect = false
): Array<MappedLineItem> | undefined => {
    if (!Array.isArray(data) || !user) {
        return undefined;
    }

    return data.map((result) => ({
        ...result,
        isUnqualifiedContractProduct: isUnqualifiedContractProduct(
            result.productCocontractor,
            result.productCocontractorValue,
            user
        ),
        isTecselect: (result.isTecselect === true && userHasTecSelect) || false,
        isAbakus: (result.isAbakus === true && user?.hasAbakus) || false,
        isAbakusPlus: (result.isAbakusPlus === true && user?.hasAbakus) || false,
        image: replaceImageUrl(result.image),
        alternativesLink: result.hasAlternatives && user ? `/produkt/${result.sku}#alternativen` : undefined,
        accessoriesLink: result.hasAccessories ? `/produkt/${result.sku}#zubehoer` : undefined,
        onlineAvailable: result.onlineAvailable
    }));
};

export default lineItemMapper;
