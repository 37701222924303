import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import type { WithTestId } from '@ab-core/testing';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import { Button as ABButton, Group } from './index.styled';

export enum BUTTON_COLOR {
    PRIMARY = 'primary',
    RED = 'red',
    WHITE = 'white',
    GRAY = 'gray',
    GREEN = 'green'
}

export enum BUTTON_VARIANT {
    FILL = 'fill',
    OUTLINE = 'outline'
}

export enum ICON_POSITION {
    LEFT = 'left',
    RIGHT = 'right'
}

export const ButtonGroup = Group;

export type ButtonType = WithTestId &
    React.ComponentPropsWithoutRef<'button'> &
    WithChildren & {
        color?: BUTTON_COLOR;
        variant?: BUTTON_VARIANT;
        iconName?: IconProps['name'];
        iconPosition?: ICON_POSITION;
        fluid?: boolean;
    };

const Button: FC<ButtonType> = (props) => {
    const {
        disabled,
        className,
        iconName,
        fluid = false,
        iconPosition = ICON_POSITION.RIGHT,
        color = BUTTON_COLOR.PRIMARY,
        variant = BUTTON_VARIANT.FILL,
        children,
        ...rest
    } = props;
    const newColor = color === BUTTON_COLOR.PRIMARY ? BUTTON_COLOR.RED : color;
    const colorClass = getClassNames({
        className,
        prefix: 'button',
        modifier: { color: newColor, disabled, variant, iconPosition, fluid }
    });

    return (
        <ABButton className={`${colorClass}`} disabled={disabled} aria-label={String(children)} {...rest}>
            {children}
            {iconName && <Icon size="SMALL_4" name={iconName} />}
        </ABButton>
    );
};

export default Button;
