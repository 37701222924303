import { gql } from '@apollo/client';

export const UPSERT_SHOPPING_LIST_LINE_ITEM = gql`
    mutation UpsertShoppingListLineItem($ids: [String!]!, $lineItemInput: [ShoppingListLineItemInput!]!) {
        upsertShoppingListLineItem(ids: $ids, lineItemInput: $lineItemInput) {
            id
            name
            slug
        }
    }
`;
