import { Colors } from '@ab-core/colors';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const Element = styled.div`
    padding: ${SPACING.SMALL};
    margin: ${SPACING.SMALL};
    &:hover {
        background-color: ${Colors.backgroundShade};
    }
`;
