import type { Contact, Customer, FeatureAccess, ShippingAddress } from '@ab-core/graphql/dist';
import { userMapperWithActiveAccount } from '@ab-core/hooks/user/userMapper';
import useText from '@ab-core/localization/src/useText';
import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import FULL_USER_QUERY from './getFullUserQuery';

type Data = {
    getCustomerData?: Customer[];
};

export type Account = {
    accountId: string;
    accountNumber: string;
    accountName?: string;
    lock?: number;
    businessUnit?: string;
    customerClassification: string;
};

export type User = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    fax: string;
    contact?: Contact;
    noSurchargeCutCost: boolean;
    cocontractors: string[];
    address?: ShippingAddress;
    accounts?: Account[];
    activeAccount?: Account;
    userName: string;
    hasAbakus: boolean;
    collectCredits: boolean;
    availableCredits?: number;
    hasShippingCosts: boolean;
    hasForeignVat?: boolean;
    hasPromotionPrice?: boolean;
    responsibleSubsidiary: string;
    deliveryAddresses?: ShippingAddress[];
    featureAccess?: FeatureAccess[];
};

type UseFullUserType = {
    user?: User;
    authError?: ApolloError;
    userLoading?: boolean;
    success?: boolean;
    caching?: boolean;
};

export const useFullUser = (caching = true): UseFullUserType => {
    const [success, setSuccess] = useState(false);
    const [user, setUser] = useState<User | undefined>(undefined);

    const skip = typeof window === 'undefined' || !localStorage.getItem('isLoggedIn');
    const { data, error, previousData, loading } = useQuery<Data>(FULL_USER_QUERY, {
        skip,
        variables: {},
        fetchPolicy: caching ? 'cache-first' : 'no-cache',
        onCompleted: () => {
            setSuccess(true);
        }
    });
    const text = useText('address.billingAddressName');
    useEffect(() => {
        if (data || previousData) {
            setUser(userMapperWithActiveAccount(text, data || previousData));
        }
    }, [data, previousData]);

    return {
        user,
        success,
        userLoading: loading,
        authError: error
    };
};
