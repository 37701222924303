import type { Mutation, MutationDeleteShoppingListArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_SHOPPING_LIST } from './gql/deleteShoppingList';
import { SHOPPING_LISTS } from './gql/shoppingLists';

type MutationDataType = Pick<Mutation, 'deleteShoppingList'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteShoppingList');

const refetchQueries = [SHOPPING_LISTS];

export const useDeleteShoppingList = (id: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteShoppingList, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationDeleteShoppingListArgs
    >(DELETE_SHOPPING_LIST, {
        variables: {
            id
        },
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteShoppingList,
        loading,
        response
    };
};
