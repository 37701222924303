import { gql } from '@apollo/client';

export const GET_CONTACT_BY_ID = gql`
    query GetContactById($contactId: String!) {
        getContactById(contactId: $contactId) {
            id
            salutation
            userName
            firstName
            lastName
            email
            onlineShopAccess
            fax
            title
            createdAt
            academicTitle
            function
            mobilePhone
            phone
            featureAccess {
                id
                feature {
                    id
                    name
                    value
                    amountValue
                    valueText
                }
            }
        }
    }
`;
