import { gql } from '@apollo/client';

export const CREATE_KANBAN_LABEL_SET = gql`
    mutation CreateKanbanLabelSet($name: String!) {
        createKanbanLabelSet(name: $name) {
            id
            createdAt
            updatedAt
            accountId
            name
            kanbanLabelIds
            count
        }
    }
`;
