import { gql } from '@apollo/client';

export const SET_OFFER_INFORMATION = gql`
    mutation setOfferInformation(
        $offerId: String!
        $shippingInput: CartShippingInput
        $offerInformationInput: CartInformationInput
    ) {
        setOfferInformation(
            offerId: $offerId
            shippingInput: $shippingInput
            offerInformationInput: $offerInformationInput
        ) {
            id
        }
    }
`;
