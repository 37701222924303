import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';
import type { ListProps } from './index';

export const StyledList = styled.ul<ListProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ textAlignment }) =>
        textAlignment === 'center' ? 'center' : textAlignment === 'right' ? 'flex-end' : 'flex-start'};
`;

export const StyledListItem = styled.li<ListProps>`
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 26px;
    ${({ listItemStyle }) =>
        listItemStyle === 'check'
            ? `
    &::before {
        position: absolute;
        content: '';
        display: inline-block;
        transform: rotate(45deg);
        height: 14px;
        left: 5px;
        top: 3px;
        margin-bottom: ${SPACING.SMALL};
        width: 6px;
        border-bottom: 1px solid ${Colors.primary};
        border-right: 1px solid ${Colors.primary};
    }
    `
            : `
    &::before {
        position: absolute;
        content: '';
        width: 6px;
        left: 0;
        top: 9px;
        height: 6px;
        background-color: ${Colors.primary};
        display: inline-block;
        border-radius: ${RADIUS.CIRCLE};
    }
    `}
    & p {
        padding-bottom: 0;
    }
`;
