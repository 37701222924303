import { SPACING } from '@ab-core/spacing';
import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 70px auto 40px;
    align-items: center;
    gap: ${SPACING.SMALL_2};
    min-height: 70px;
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
`;

export const ImageContainer = styled.div`
    height: 70px;
    width: 70px;
`;

export const TitleContainer = styled.div`
    text-transform: uppercase;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 2rem;
`;
