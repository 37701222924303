import { BUTTON_VARIANT } from '@ab-core/button';
import { ROUTES } from '@ab-core/functions';
import useText from '@ab-core/localization/src/useText';
import Spacer from '@ab-core/spacer';
import { navigationStore } from '@ab-core/store';
import { navigate } from 'gatsby';
import React from 'react';
import { TextButtonBox } from '../TextButtonBox';

export const Register = () => {
    const handleSignUp = () => {
        navigationStore.setters.setUserNavigation(false);
        navigationStore.setters.setUserDropdownNavigation(false);
        navigate(ROUTES.SIGNUP);
    };

    const headlineText = useText('loginBox.signUpHeadline');
    const text = useText('loginBox.signUpText');
    const buttonText = useText('loginBox.signUpButtonText');

    return (
        <>
            <TextButtonBox
                headlineText={headlineText}
                text={text}
                buttonText={buttonText}
                handleClick={handleSignUp}
                variant={BUTTON_VARIANT.FILL}
            />

            <Spacer />
        </>
    );
};
