import { userCanSeeTecselect } from '@ab-core-functions/user-permission';
import type { Query, QueryShoppingListArgs, ShoppingList } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import type { MappedLineItem } from '@ab-core/hooks/shopping/lineItemMapper';
import lineItemMapper from '@ab-core/hooks/shopping/lineItemMapper';
import type { User } from '@ab-core/types/src';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SHOPPING_LIST } from './gql/shoppingList';

type ShoppingListDataMapperType = Omit<ShoppingList, 'lineItems'> & {
    lineItems?: Array<MappedLineItem>;
};

const shoppingListDataMapper = (
    shoppingList: ShoppingList,
    user?: User,
    userHasTecSelect = false
): ShoppingListDataMapperType => {
    const lineItems = lineItemMapper(shoppingList.lineItems || [], user, userHasTecSelect);

    return {
        ...shoppingList,
        lineItems
    };
};

type QueryDataType = Pick<Query, 'shoppingList'>;
type OutgoingDataType = ShoppingListDataMapperType | undefined;

export const useShoppingList = (id: string, skip = false) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);
    const { userData } = useAuth();
    const userHasTecSelect = userCanSeeTecselect();

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryShoppingListArgs>(SHOPPING_LIST, {
        fetchPolicy: 'network-only',
        variables: {
            id
        },
        skip
    });

    useEffect(() => {
        if (data?.shoppingList) {
            const mappedData = shoppingListDataMapper(data.shoppingList, userData, userHasTecSelect);
            setOutgoingData(mappedData);
        }
    }, [data, userData, userHasTecSelect]);

    return {
        shoppingList: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
