import { defaultCartIsSharedCart } from '@ab-core-functions/user-permission';
import useIdsSession from '@ab-core/hooks/user/useIdsSession';
import { useSessionCart } from '@ab-core/hooks/user/useSessionCart';
import { cartStore } from '@ab-core/store';
import CreateCart from '../createCart/createCart';
import { useMyCarts } from '../getCarts';
import cartMapper from '../mappers/cartMapper';
import SetActiveCart from './setActiveCart';
import getActiveCart from './useActiveCart';

const useSetActiveCart = (skip = false) => {
    const { myCartsRefetch } = useMyCarts(true, true);
    const { activeCartRefetch } = getActiveCart(skip);
    const { setActiveCart } = SetActiveCart('');
    const { isIdsSession } = useIdsSession();
    const { checkForSessionCart } = useSessionCart();
    const standardCartName = 'Neuer Warenkorb (Standard)';
    const { createCart: createCartMutation } = CreateCart(standardCartName);
    const checkForActiveCartInProgress = cartStore.getters.useActiveCartInProgress();
    const userHasDefaultSharedCart = defaultCartIsSharedCart();

    const checkForActiveCart = async () => {
        try {
            const activeCart = await activeCartRefetch();

            if (
                checkForActiveCartInProgress ||
                isIdsSession ||
                typeof window === 'undefined' ||
                !localStorage.getItem('isLoggedIn')
            ) {
                return;
            }

            const { ociSessionId } = checkForSessionCart();
            cartStore.setters.setActiveCartInProgress(true);

            if (ociSessionId) {
                await checkSessionCart(ociSessionId);

                return;
            }

            if (!activeCart) {
                const refetchedCarts = await myCartsRefetch();
                let carts = refetchedCarts.data.getMyCarts?.map((cart) => cartMapper(cart));

                carts = carts?.filter((cart) => !cart?.isIdsCart);

                if (!carts) {
                    return;
                } else if (carts.length === 0) {
                    await createCart();
                } else if (!carts.some((cart) => cart.isActiveCart)) {
                    await setCartActive(carts[0].id);
                }
            }
        } catch (error) {
            throw new Error(`CheckForActiveCart failed: ${error.message}`);
        } finally {
            cartStore.setters.setActiveCartInProgress(false);
        }
    };

    const setCartActive = async (id: string, ociSessionId?: string) => {
        const variables = {
            variables: {
                cartId: id,
                cartInformationInput: {
                    fields: [{ name: 'cart-is-active', value: 'true' }]
                }
            }
        };

        if (ociSessionId) {
            variables.variables.cartInformationInput.fields.push({ name: 'cart-sessionId', value: ociSessionId });
        }

        await setActiveCart(variables);
    };

    const createCart = async () => {
        const cart = await createCartMutation({
            variables: {
                cartInput: {
                    custom: [
                        { name: 'name', value: standardCartName },
                        { name: 'shareState', value: userHasDefaultSharedCart ? 'public' : 'private' }
                    ]
                }
            }
        });

        if (cart && cart?.data?.createMyCart?.id) {
            await setCartActive(cart.data.createMyCart.id);
        }
    };

    const checkSessionCart = async (ociSessionId: string) => {
        const newOciCartName = `Neuer Warenkorb ${new Date().toLocaleString('de', {
            timeZone: 'Europe/Berlin'
        })}`;

        const refetchedCarts = await myCartsRefetch({ sessionId: ociSessionId });
        const carts = refetchedCarts.data.getMyCarts?.map((cart) => cartMapper(cart));
        const activeCart = carts?.find((cart) => cart.isActiveCart);

        const hasActiveSessionCart = activeCart?.custom.customFieldsRaw.some(
            (field) => field.name === 'cart-sessionId' && field.value === ociSessionId
        );

        const hasOwnSessionCartInList = carts?.find((cart) => cart.sessionId === ociSessionId);

        if (!hasActiveSessionCart && !hasOwnSessionCartInList) {
            // Kein Aktiver und es existiert auch keiner
            const cart = await createCartMutation({
                variables: {
                    cartInput: {
                        custom: [
                            { name: 'name', value: newOciCartName },
                            { name: 'sessionId', value: ociSessionId }
                        ]
                    }
                }
            });

            if (cart.data) {
                await setCartActive(cart.data.createMyCart?.id, ociSessionId);
            }
        } else if (!hasActiveSessionCart && !!hasOwnSessionCartInList) {
            // Kein Aktiver aber es gibt einen
            await setCartActive(hasOwnSessionCartInList.id, ociSessionId);
        }

        cartStore.setters.setActiveCartInProgress(false);
    };

    return { checkForActiveCart };
};

export { useSetActiveCart };
