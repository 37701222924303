import type { Order } from '@ab-core/graphql/dist/index';

function getIsOrder(item: Order): boolean {
    const orderType = item?.custom?.customFieldsRaw?.find(
        (customField) => customField.name === 'cart-bestellart'
    )?.value;

    const offerNumber = item?.custom?.customFieldsRaw?.find(
        (customField) => customField.name === 'cart-angebotsnummer'
    )?.value;

    return !(orderType === 'AN' && !offerNumber);
}
const orderMapper = (data?: Order) => {
    if (!data) {
        return undefined;
    }

    return {
        id: data.id,
        orderNumber: data.orderNumber,
        isOrder: getIsOrder(data)
    };
};

export default orderMapper;
