import type { Mutation, MutationDeleteShoppingListLineItemArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_SHOPPING_LIST_LINE_ITEM } from './gql/deleteShoppingListLineItem';
import { SHOPPING_LIST } from './gql/shoppingList';

type MutationDataType = Pick<Mutation, 'deleteShoppingListLineItem'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteShoppingListLineItem');

const refetchQueries = [SHOPPING_LIST];

export const useDeleteShoppingListLineItem = (variables: MutationDeleteShoppingListLineItemArgs) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteShoppingListLineItem, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationDeleteShoppingListLineItemArgs
    >(DELETE_SHOPPING_LIST_LINE_ITEM, {
        variables,
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteShoppingListLineItem,
        loading,
        response
    };
};
