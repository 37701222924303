import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_ACCOUNTS } from './gql/getAccounts';

type QueryDataType = Pick<Query, 'getAccounts'>;
type OutgoingDataType = QueryDataType['getAccounts'];
type IncomingDataType = { skip?: boolean; accountIdForSort?: string };

const onError = getErrorLoggerFunction('getAccounts');

export const useGetAccounts = (props: IncomingDataType) => {
    const { skip = false, accountIdForSort } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_ACCOUNTS, {
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            if (accountIdForSort) {
                data?.getAccounts?.sort((a, b) => {
                    if (a.accountId === accountIdForSort) {
                        return -1;
                    }

                    if (b.accountId === accountIdForSort) {
                        return 1;
                    }

                    return 0;
                });
            }

            setOutgoingData(data?.getAccounts);
        }
    }, [data]);

    return {
        accounts: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
