import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { css } from 'styled-components';

export const buttonVariantRed = css`
    &.ab-button-color-red {
        color: ${Colors.white};
        border-color: ${Colors.white};
        background: ${Colors.primary};
        &:hover,
        &.hover {
            border-color: ${Colors.primaryDarkenOne};
            background: ${Colors.primaryDarkenOne};
        }
        &:active,
        &.active {
            border-color: ${Colors.primaryDarkenTwo};
            background: ${Colors.primaryDarkenTwo};
        }

        &.ab-button-disabled {
            border-color: ${Colors.primaryLightenOne};
            background: ${Colors.primaryLightenOne};
        }
        &.ab-button-variant-outline {
            border-radius: ${RADIUS.MINIMAL};
            border: 2px solid ${Colors.primary};
            color: ${Colors.primary};
            background: transparent;

            &:hover,
            &.hover {
                color: ${Colors.primaryDarkenOne};
                border-color: ${Colors.primaryDarkenOne};
                background: transparent;
            }
            &:active,
            &.active {
                color: ${Colors.primaryDarkenTwo};
                border-color: ${Colors.primaryDarkenTwo};
                background: transparent;
            }
            &.ab-button-disabled {
                color: ${Colors.primaryLightenOne};
                border-color: ${Colors.primaryLightenOne};
                background: transparent;
            }
        }
    }
`;

export const buttonVariantWhite = css`
    &.ab-button-color-white {
        color: ${Colors.primary};
        border-color: ${Colors.white};
        background: ${Colors.white};
        &:hover,
        &.hover {
            color: ${Colors.primaryDarkenOne};
            border-color: ${Colors.white};
            background: ${Colors.white};
        }
        &:active,
        &.active {
            color: ${Colors.primaryDarkenTwo};
            border-color: ${Colors.white};
            background: ${Colors.white};
        }

        &.ab-button-disabled {
            color: ${Colors.primaryLightenOne};
            border-color: ${Colors.gray15};
            background: ${Colors.gray15};
        }
        &.ab-button-variant-outline {
            border-radius: ${RADIUS.MINIMAL};
            border: 2px solid white;
            color: ${Colors.primary};
            background: transparent;

            &:hover,
            &.hover {
                color: ${Colors.primary};
                background: ${Colors.white};
            }
            &:active,
            &.active {
                color: ${Colors.primary};
                background: ${Colors.white};
            }
            &.ab-button-disabled {
                color: ${Colors.gray15};
                border-color: ${Colors.gray15};
                background: transparent;
            }
        }
    }
`;

export const buttonVariantGray = css`
    &.ab-button-color-gray {
        color: ${Colors.white};
        border-color: ${Colors.gray50};
        background: ${Colors.gray50};
        &:hover,
        &.hover {
            color: ${Colors.white};
            border-color: ${Colors.gray70};
            background: ${Colors.gray70};
        }

        &:active,
        &.active {
            color: ${Colors.white};
            border-color: ${Colors.gray70};
            background: ${Colors.gray70};
        }

        &.ab-button-disabled {
            color: ${Colors.white};
            border-color: ${Colors.gray30};
            background: ${Colors.gray30};
        }
        &.ab-button-variant-outline {
            border-radius: ${RADIUS.MINIMAL};
            border: 2px solid ${Colors.gray70};
            color: ${Colors.gray70};
            border-color: ${Colors.gray70};
            background: transparent;

            &:hover,
            &.hover {
                color: ${Colors.gray70};
                border-color: ${Colors.gray70};
                background: transparent;
            }
            &:active,
            &.active {
                border-color: ${Colors.gray70};
                background: ${Colors.gray70};
                background: transparent;
            }
            &.ab-button-disabled {
                color: ${Colors.gray15};
                border-color: ${Colors.gray30};

                background: transparent;
            }
        }
    }
`;

export const buttonVariantGreen = css`
    &.ab-button-color-green {
        color: ${Colors.white};
        border-color: ${Colors.success};
        background: ${Colors.success};

        &:hover,
        &.hover {
            color: ${Colors.white};
            border-color: ${Colors.successDarkenOne};
            background: ${Colors.successDarkenOne};
        }
        &:active,
        &.active {
            color: ${Colors.white};
            border-color: ${Colors.successDarkenTwo};
            background: ${Colors.successDarkenTwo};
        }

        &.ab-button-disabled {
            color: ${Colors.white};
            border-color: ${Colors.successLightenOne};
            background: ${Colors.successLightenOne};
        }
        &.ab-button-variant-outline {
            border-radius: ${RADIUS.MINIMAL};
            border: 2px solid ${Colors.success};
            color: ${Colors.success};
            border-color: ${Colors.success};
            background: transparent;

            &:hover,
            &.hover {
                color: ${Colors.successDarkenOne};
                border-color: ${Colors.successDarkenOne};
                background: transparent;
            }
            &:active,
            &.active {
                color: ${Colors.successDarkenTwo};
                border-color: ${Colors.successDarkenTwo};
                background: transparent;
            }
            &.ab-button-disabled {
                color: ${Colors.successLightenOne};
                border-color: ${Colors.successLightenOne};
                background: transparent;
            }
        }
    }
`;
